<template>
  <div class="buses">
    <div class="text-design">
      <span style="color: 004AAD" class="departure">Departure: Available Buses</span>

    </div>
    <!-- desktop -->
    <v-expansion-panels class="desktop" flat v-model="panel">
      <v-expansion-panel v-for="(trip,index) in oneWayTrips" :key="index" >
        <v-expansion-panel-header>
          <v-row class="tw-items-center ">
            <v-col lg="3" sm="6" md="3" class="tw-flex tw-w-full tw-items-center tw-flex-row">
              <img :src="trip.fleetForThisTrip.vehicleImage" alt="" class="company-logo">
              <p class="company-name text-capitalize ml-7 mt-3"> {{getFirstText(trip.fleetForThisTrip.vehicleBrand)}} </p>
            </v-col>
            <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
              <p class="route uppercase text-uppercase">
                {{trip.routeDepartureCity.substring(0,3)}}-{{trip.routeDestinationCity.substring(0,3)}} </p>
            </v-col>
            <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
              <p class="departure-time"> {{getDateTime(trip.takeOffDate,trip.takeOffTime)}} </p>
            </v-col>
            <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center tw-justify-center">
              <p class="seats-available"> {{getAvailableSeats(trip.fleetForThisTrip.seats)}} Seats Available</p>

            </v-col>
            <v-col lg="1" sm="6" md="1" class=" tw-w-full tw-items-center" v-if="!trip.tripRegion || trip.tripRegion === 'local'">
              <p class="amount"> ₦{{trip.tripAmount}} </p>

            </v-col>
            <v-col lg="2" sm="6" md="2"
              class="tw-flex tw-w-full tw-items-center md:tw-justify-evenly tw-flex-wrap button-handler">
              <v-btn  style="background-color: #004aad" class="book cta" text
                     @click="handleSelectedTrip(trip)"
                :disabled="getBookingStatus(trip.fleetForThisTrip.seats,trip.status) !== 'Book Now'"
                >{{getBookingStatus(trip.fleetForThisTrip.seats,trip.status)}}</v-btn>
<!--              <v-btn class="schedule cta" text> Schedule Trip</v-btn>-->
            </v-col>
          </v-row>

        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <div class="dropdown tw-flex">

            <div style="width:30px" class="group"><img src="../../assets/from-to.svg" alt=""></div>
            <div class="main-left">
              <div class="first-line ">
                <div class="left tw-flex">
                  <p class=" show text-capitalize "> {{getFirstText(trip.routeDepartureState)}}
                    ({{toSentenceCase(trip.routeDepartureCity)}} - {{toSentenceCase(trip.routeDepartureTerminalName)}})</p>
                  <p class=" hide text-capitalize"> {{trip.routeDepartureCity}}</p>

                </div>
                <div class="right" v-if="!trip.tripRegion || trip.tripRegion === 'local'">
                  <span  style="background-color: #004aad"
                        class="amount tw-hidden md:tw-flex"> ₦{{trip.tripAmount}} </span>
                </div>

              </div>
              <div class="second-line my-3">
                <div class="left tw-flex tw-items-center">
                  <span class="time-etimate ">( Stops ) {{trip.tripStops}}</span>
                </div>
                <div class="right">
                  <span class="available-seats  tw-hidden md:tw-flex"> Available Seats : {{getAvailableSeats(trip.fleetForThisTrip.seats)}}
                  </span>
                </div>

              </div>
              <div class="third-line mt-7 tw-flex">
                <span class="show text-capitalize">{{getFirstText(trip.routeDestinationState)}}
                ({{toSentenceCase(trip.routeDestinationCity)}} - {{toSentenceCase(trip.routeDestinationTerminalName)}})</span>
                <span class="hide text-capitalize">{{trip.routeDepartureCity}}</span>
              </div>
              <span class="available-seats second-as  tw-flex md:tw-hidden my-5"> Available Seats :
                {{getAvailableSeats(trip.fleetForThisTrip.seats)}}
              </span>
                <div class="right" v-if="trip.tripRegion === 'crossCountry'">
                    <v-radio-group class="tw-hidden md:tw-flex tw-flex tw-items-center" row v-model="trip.selectedTripCategory">
                        <v-radio v-for="(tripCategory,index) in trip.tripCategories" :key="index" color="#004aad" :value="tripCategory">
                            <template #label>
                                <label class="trip-category text-capitalize">{{tripCategory.type}} &#8358; {{tripCategory.tripAmount}}</label>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
              <div class="footer my-5 ">
                <span class="bus-type"> {{trip.fleetForThisTrip.vehicleType}}</span>
                <span class="mx-2 dot">.</span>
                <span class="bus-class"> {{trip.fleetForThisTrip.vehicleClass}}</span>
              </div>
            </div>
          </div>
         
        </v-expansion-panel-content>
        <v-divider inset ></v-divider>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- //mobile -->
    <v-expansion-panels class="mobile" v-model="panel">
      <v-expansion-panel v-for="(trip,index) in oneWayTrips" :key="index" flat >
        <v-expansion-panel-header>
          <div class="tw-flex">
            <div class="up-left">
              <img :src="trip.fleetForThisTrip.vehicleImage" alt="" class="company-logo">
            </div>
            <div class="up-right ">
              <v-row class="ml-1 justify-between">
                <v-col sm="6" class=" tw-items-center ">

                  <p class="company-name text-capitalize mt-3"> {{getFirstText(trip.fleetForThisTrip.vehicleBrand)}} </p>
                </v-col>
                <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
                  <p class="route text-uppercase"> {{trip.routeDepartureCity.substring(0,3)}}-{{trip.routeDestinationCity.substring(0,3)}}
                  </p>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col sm="6" class=" tw-items-center">
                  <p class="departure-time"> {{getDateTime(trip.takeOffDate,trip.takeOffTime)}} </p>
                </v-col>
                <v-col sm="6" class=" tw-w-full tw-items-center" v-if="!trip.tripRegion || trip.tripRegion === 'local'">
                  <p style="color : #004aad"
                      class="amount tw-pl-5"> ₦{{trip.tripAmount}} </p>
                </v-col>
              </v-row>
            </div>
          </div>

        </v-expansion-panel-header>
        <v-expansion-panel-content>
 <div class="dropdown tw-flex">

            <div style="width:30px" class="group"><img src="../../assets/from-to.svg" alt=""></div>
            <div class="main-left">
              <div class="first-line ">
                <div class="left tw-flex">
<!--                  <span class=""> {{getTime(trip.takeOffDate,trip.takeOffTime)}} </span>-->
                  <p class=" show text-capitalize "> {{getFirstText(trip.routeDepartureState)}}
                    ({{toSentenceCase(trip.routeDepartureCity)}} - {{toSentenceCase(trip.routeDepartureTerminalName)}})</p>
                  <p class=" hide text-capitalize ">{{getFirstText(trip.routeDepartureState)}} ({{toSentenceCase(trip.routeDepartureTerminalName)}})</p>

                </div>
                <div class="right">
                  <span style="color: #004aad" class="amount tw-hidden md:tw-flex"> ₦{{trip.tripAmount}}</span>
                </div>
              </div>
              <div class="second-line tw-pb-1 tw-mt-3">
                  <div class="left tw-flex tw-items-center tw-flex-wrap tw-mt-5">
                      <span class="time-etimate">( Stops ) {{trip.tripStops}}</span>
                  </div>
                <div class="right">
                  <span class="available-seats  tw-hidden md:tw-flex"> Available Seats : {{getAvailableSeats(trip.fleetForThisTrip.seats)}}
                  </span>
                </div>

              </div>

              <div class="third-line mt-7 tw-flex">
<!--                <span>{{getTime(trip.takeOffDate,trip.takeOffTime)}}</span>-->
                <span class="show text-capitalize">{{getFirstText(trip.routeDestinationState)}}
                ({{toSentenceCase(trip.routeDestinationCity)}} - {{toSentenceCase(trip.routeDestinationTerminalName)}})</span>
                <span class="hide text-capitalize">{{getFirstText(trip.routeDestinationState)}} ({{toSentenceCase(trip.routeDestinationTerminalName)}})</span>
              </div>
                <div class="right" v-if="trip.tripRegion === 'crossCountry'">
                    <v-radio-group class="tw-flex tw-items-center"  v-model="trip.selectedTripCategory">
                        <v-radio v-for="(tripCategory,index) in trip.tripCategories" :key="index" color="#004aad" :value="tripCategory">
                            <template #label>
                              <div class="trip-category text-capitalize tw-flex tw-flex-row">
                                <div class="tw-w-7/12">{{tripCategory.type}}</div>
                                <div class="tw-w-5/12">
                                  &#8358;
                                  {{tripCategory.tripAmount}}
                                </div>
                              </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
              <span class="available-seats second-as  tw-flex md:tw-hidden " :class="[trip.tripRegion === 'crossCountry' ? 'tw-mb-10' : 'tw-my-10']"> Available Seats :
                {{getAvailableSeats(trip.fleetForThisTrip.seats)}}
              </span>
              <div class="footer my-5 ">
                <span class="bus-type"> {{trip.fleetForThisTrip.vehicleType}}</span>
                <span class="mx-2 dot">.</span>
                <span class="bus-class"> {{trip.fleetForThisTrip.vehicleClass}}</span>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-items-center  tw-flex-wrap button-handler">
            <v-btn style="background-color: 004AAD" class="book cta"  @click="handleSelectedTrip(trip)">{{getBookingStatus(trip.fleetForThisTrip.seats,trip.status)}}</v-btn>
<!--            <v-btn class="schedule cta" text> Schedule Trip</v-btn>-->
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="tw-flex tw-w-full tw-justify-end tw-my-3">
      <v-btn style="background-color: 004AAD"
             class="load-more" text v-if="getQueryData" @click="loadMoreTrip" :loading="moreLoading">More</v-btn>
    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import dayjs from "dayjs";

  export default {
    components: {},
    data: () => ({
      items: ['Lowest Price', 'Highest Price'],
        panel:0,
      moreLoading: false,
      oneWayData:{}

    }),
    computed:{
      ...mapGetters("booking", ["oneWayTrips","pendingTrips"]),
      ...mapGetters("seatsSelection",["seats"]),
      getQueryData(){
        return this.oneWayTrips[0].totalTrips > this.oneWayTrips.length;
      }
    },
    methods:{
     async loadMoreTrip(){
        this.moreLoading = true
       if (!this.oneWayData.page || this.oneWayData.page === 0){
          this.oneWayData.page = 1
       }
       else {
         this.oneWayData.page ++
       }
        let data = {}
        // data.transportCompanyId = this.companyData.id
        data.page = this.oneWayData.page
        data.pageSize = 50
        data.departureQuery = this.oneWayData.departure
        data.destinationQuery = this.oneWayData.destination
        data.takeOffDate = this.oneWayData.travelDates
       data.departureIsCity = this.oneWayData.departureIsCity
       data.departureIsState = this.oneWayData.departureIsState
       data.destinationIsCity = this.oneWayData.destinationIsCity
       data.destinationIsState = this.oneWayData.destinationIsState
        await this.$store.dispatch("tripAndTravellerDetails/setPendingTrips", data).then(() => {
          this.moreLoading = false
          this.getQueryTrips()
        })
      },
      handleSelectedTrip(trip){
        if (trip.tripRegion === 'crossCountry'){
            if (trip.selectedTripCategory){
                sessionStorage.setItem("userSelectedCategory", JSON.stringify(trip.selectedTripCategory))
                this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip",trip );
                this.$router.push({name: 'OneWaySelectSeats'})
            }
            else {
                this.$displaySnackbar({
                    message: "Please Select Your Identification Category"
                })
            }
        }else {
            this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip",trip );
            this.$router.push({name: 'OneWaySelectSeats'})
        }
      },

      getTime(date,time){
        return dayjs(date+time).format('LT')
      },
      getDateTime(date,time){
        return dayjs(date+time).format("lll")
      },
      getAvailableSeats(seats){
        let availableSeats = 0
        seats.forEach(seat =>{
          if(seat.status === "AVAILABLE"){
            availableSeats += 1
          }
        })
        return availableSeats -1 - this.seats.length
      },
      toSentenceCase(text){
        text = text.toLowerCase()
        return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
      },
        getFirstText(text){
            if (text) {
                let arrayText = text.split(" ")
                return this.toSentenceCase(arrayText[0])
            }
        },

      getBookingStatus(seats, status){
        let availableSeats = this.getAvailableSeats(seats)
        if(status === 'PENDING' && availableSeats > 0){
          return "Book Now"}
        else if(status === 'PENDING' && availableSeats === 0){
          return  "Booked"}
        else if(status !== 'PENDING'){
          return status}
      },
      getQueryTrips() {
        this.oneWayData.departure = this.getSubstringBeforeComma(this.getCity(this.oneWayData.departure))
        this.oneWayData.destination = this.getSubstringBeforeComma(this.getCity(this.oneWayData.destination))
        let queryTrips = []
        this.pendingTrips.forEach(trip =>{
          let tripDate = dayjs(trip.takeOffDate +trip.takeOffTime).format("LLL")
          if (dayjs(tripDate).date() === dayjs().date()) {
            let selectedDate = dayjs(this.getUserSelectedDate(this.oneWayData.travelDates)).format("LLL")
            if (dayjs(tripDate).isSame(dayjs(selectedDate))|| dayjs( tripDate).isAfter(dayjs(selectedDate))) {
              queryTrips.push(trip)
            }
          }
          else if (dayjs(tripDate).date() > dayjs().date()){
            queryTrips.push(trip)
          }
        })
        let uniqueTrips = new Set()
        queryTrips.forEach(trip => uniqueTrips.add(JSON.stringify(trip)))
        let filteredTrips = []
        uniqueTrips.forEach(trip => filteredTrips.push(JSON.parse(trip)))

        if (filteredTrips.length) {
          let oneWayTrips = this.oneWayTrips
          oneWayTrips = oneWayTrips.concat(queryTrips)
          sessionStorage.setItem("queryData", JSON.stringify(this.oneWayData));
          sessionStorage.setItem("queryTrips", JSON.stringify(oneWayTrips));
          this.$store.dispatch("tripAndTravellerDetails/setOneWayTrips", oneWayTrips);
        } else {
          this.$displaySnackbar({
            message: "Your search query did not match any more trip"
          });
        }
      },
      getSubstringBeforeComma(str) {
        const index = str.indexOf(" - ");
        if (index === -1) {
          return str;
        } else {
          return str.substring(index + 3);
        }
      },
      getUserSelectedDate(date) {
        let todate = new Date(Date.now())
        let today = dayjs(date+"T"+todate.getHours()+":"+todate.getMinutes())
        if (date) {
          return dayjs(today).format("LLL")
        } else {
          return dayjs().format('YYYY-MM-DDTHH:mm:ss')
        }
      },
      getCity(city){
        if (city.state){
          return city.state + " - "+city.city
        }
        else {
          return  city
        }
      },
    },
    created() {
      this.oneWayData = JSON.parse(sessionStorage.getItem("queryData"))
    }
  }
</script>

<style lang='scss' scoped>
  .desktop {
    @media (max-width:960px) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media (max-width:960px) {
      display: flex;
    }
  }


  .buses {
    margin: 0 11rem 5rem;

    @media (max-width:1264px) {
      margin: 0 2rem 5rem;
    }

    @media screen and (max-width: 768px){
      margin: 1rem;
    }


  }

  .col {
    padding: 5px;
  }

  .text-design {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .v-select {
    width: 150px !important;

    @media (max-width:567px) {
      width: 70px !important;
    }
  }


  .departure {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #004AAD;

    @media (max-width:960px) {
      font-size: 13px;
    }
  }

  .sort,
  .v-list-item__title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;

    @media (max-width:960px) {
      font-size: 10px;
    }
  }

  .available-buses {
    display: flex;
    justify-content: space-evenly;
  }

  .company-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: contain;

    @media (max-width:960px) {
      width: 40px;
      height: 40px;
    }
  }

  .company-name {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #4F4F4F;
  }

  .route,
  .departure-time,
  .seats-available,
  .amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 15px;
    line-height: 17px;
    color: #4F4F4F;

    @media (MAX-WIDTH:960px) {
      font-size: 11px;

    }

  }

  .v-application p {
    margin-bottom: 0 !important;
  }

  .cta {
    text-transform: none;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .load-more{
    text-transform: none;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FAFAFA !important;
  }

  .book {
    color: #FAFAFA !important;
    width: 123px;
    height: 37px;
    background-color: #004AAD;
    border-radius: 4px;

    @media (max-width: 576px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .button-handler {
    align-items: flex-start;
    margin: 20px 0;

    @media (max-width:1520px) {
      flex-direction: column;
    }

    @media (max-width:960px) {
      flex-direction: row !important;
      margin: 20px 0 40px 80px;
    }

    @media (max-width:768px) {
      margin: 20px 0 40px 53px;

    }

  }

  .schedule {
    color: #004aad;

    width: 123px;
    height: 37px;
    border: 1px solid #004AAD;
    border-radius: 4px;
    margin-left: 10px;

    @media (max-width:1520px) {
      margin-left: 0;
      margin-top: 20px;
    }

    @media (max-width:960px) {
      margin-top: 0;
      margin-left: 15px;

    }

    @media (max-width:576px) {
      width: 100%;
      margin-left: 0;
    }


  }

  .up-left {
    width: 70px;

  }

  .up-right {
    width: 100%;
  }

  .dropdown {
    margin-top: 30px;
  }

  .group {
    margin-left: 80px;

    @media (max-width:768px) {
      margin-left: 57px;
    }
  }

  .show {
    display: flex;

    @media (max-width:576px) {
      display: none
    }
  }

  .hide {
    display: none;

    @media (max-width:576px) {
      display: flex
    }
  }

  .main-left {
    width: 70%;

    @media (max-width:960px) {
      width: 90%;
    }
  }

  .first-line,
  .second-line {
    display: flex;
    justify-content: space-between !important;
  }

  .dot {
    font-size: 15px;
  }

  .first-line .left,
  .third-line {

    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;

  }

  .first-line .right .amount {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    color: #004AAD;

  }

  .second-line,
  .footer,
  .second-as {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;

  }

  .second-as {
    margin-left: -30px;
  }

  .footer {
    color: #878787;
    margin-left: -30px;
    line-height: 20px;

  }
 ::v-deep .v-text-field input {
    padding: 0 !important;
  }

  ::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    display: flex !important;
  }

  .trip-category {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
    width: 100%;
    @media (max-width:960px) {
      font-size: 11px;
    }
  }
</style>