<template>
  <div class="tw-w-full">
    <loader :loading="loading" v-if="loading"/>
  <div class="step-one " v-else >
    <div class="tw-w-full">
    <booking-steps/>
    <one-way-search />
    <available-trips />
    </div>
  </div>

  </div>
</template>

<script>
import BookingSteps from '@/components/reuseables/booking/OneWayBookingSteps'
  import AvailableTrips from '@/views/OneWayBooking/AvailableTrips.vue'
  import OneWaySearch from '@/views/OneWayBooking/OneWaySearch'
import  defaultLogo from "@/assets/bluelogo.png"
import Loader from "@/components/reuseables/Loader";
import {mapGetters} from "vuex";
  export default {
    data() {
      return {
        tab: null,
        defaultLogo
      }
    },
    components: {
      Loader,
      OneWaySearch,
      AvailableTrips,
        BookingSteps
    },
    computed:{
      ...mapGetters('companyProfile',['companyData','loading'])
    },

   created() {
   }
  }
</script>

<style lang='scss' scoped>
.company-logo{
  width: auto;
  height: 40px;
}

.container{
  min-height: 96vh;
  height: auto;
}

.theme--light.v-footer {
  background: none !important;
}
</style>